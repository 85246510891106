import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import PageNotFound from '../views/404.vue';

Vue.use(VueRouter)

const routes = [
  // ENHANCEMENT WORKARROUND KALAU SLUG ADA YANG SAMA
    // {
    //   path: "/:id/:slug",
    //   name: 'Home',
    //   component: Home,
    // },
    {
      path: "/:slug",
      name: 'Home',
      component: Home,
    },
    {
      path: '*',
      redirect: { name: 'PageNotFound' }
    },
    {
      path: '/not-found',
      name: 'PageNotFound',
      component: PageNotFound
    }
];

const router = new VueRouter({
  routes,
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
})

export default router